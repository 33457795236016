import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const WebcamCapture = ({ setCaptureImage, handleFileChange }) => {
  const webcamRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const toggleModalCamera = () => {
    setIsCameraOpen(!isCameraOpen);
    setIsCameraOpen(false);
  };

  const capture = () => {
    // setIsCameraOpen(false);
    const imageSrc = webcamRef.current.getScreenshot();
    setPreview(imageSrc);
    const blob = dataURItoBlob(imageSrc);
    const file = new File([blob], "photo.jpg", { type: "image/jpeg" });
    setCaptureImage(file);
  };

  const toggleCamera = () => {
    setPreview(null);
    setIsCameraOpen((prevState) => !prevState);
  };

  const closeCamera = () => {
    setIsCameraOpen(false);
  };

  function dataURItoBlob(dataURI) {
    if (dataURI === null) {
      toast.error("Camera not found");
      return;
    }
    const byteString = atob(dataURI?.split(",")[1]);
    const mimeString = dataURI?.split(",")[0]?.split(":")[1]?.split(";")[0];
    const ab = new ArrayBuffer(byteString?.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString?.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const handleReCapture = () => {
    setPreview(null);
    //setIsCameraOpen((prevState) => !prevState);
  };

  return (
    <>
      <button onClick={toggleCamera} className="dropzone" type="button">
        <span className="icon">
          {" "}
          <i class="fa-solid fa-camera"></i>{" "}
        </span>
        <p className="mt-md-3 mt-2 text16 fw-bold">Camera</p>
      </button>

      <Modal
        isOpen={isCameraOpen}
        toggle={toggleModalCamera}
        className="modal-dialog-centered custom-modal"
      >
        <ModalHeader
          toggle={toggleModalCamera}
          id="imgShareModalLabel"
        ></ModalHeader>
        <ModalBody>
          {preview ? (
            <img src={preview} alt="Captured" className="img-fluid scale-1" />
          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className="img-fluid scale-1"
            />
          )}
        </ModalBody>
        <ModalFooter className="justify-content-center">
          {preview && (
            <button className="btns btns--calypso" onClick={handleReCapture}>
              {" "}
              <span>Recapture</span>
            </button>
          )}
          {preview && (
            <button
              className="btns btns--calypso"
              onClick={() => {
                setIsCameraOpen((prevState) => !prevState);
                handleFileChange();
              }}
            >
              {" "}
              <span>Done</span>
            </button>
          )}
          {preview == null && (
            <button className="btns btns--calypso" onClick={capture}>
              <span>Capture</span>
            </button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default WebcamCapture;
