  import { useLocation, useNavigate } from "react-router-dom";
  import React, { useEffect } from "react";
  import CryptoJS from "crypto-js";
  import Cookies from "js-cookie";
  import { decryptTokenFlutter } from "../ApiHalper/ApiHelper";

  export const CallFunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const paramValue = queryParams.get("token");
  
      if (paramValue) {
          // Decode the token using decodeURIComponent
          let decodedToken = decodeURIComponent(paramValue);  
          // Replace spaces with '+' if needed
          decodedToken = decodedToken.replace(/ /g, '+');

          const flutterDecodeToken = decryptTokenFlutter(decodedToken,"webbytroops")

          const reactEncryptedToken = CryptoJS.AES.encrypt(flutterDecodeToken, "webbytroops").toString();
  
          console.log(flutterDecodeToken)
          // Set the cookie with the corrected token (no need to encode again)
          Cookies.set("token", reactEncryptedToken, {
              expires: 1, // Cookie expiration in days
              secure: true, // Ensures the cookie is sent over HTTPS
              sameSite: "Strict", // Helps prevent CSRF
          });
  
          // Navigate after setting the cookie
          // if (Cookies.get("token") === decodedToken) {
              navigate("/faceRecognize/android/app");
          // } else {
          //     console.log("Failed to set the cookie.");
          // }
      }
  }, [location, navigate]);

    return null; // Do not render any UI
  };







    
