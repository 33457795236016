import React, { useEffect, useRef, useState } from "react";
import {
  DeleteDataWithToken,
  GetDataWithToken,
  PostDataWithTokenNodeServerFormData,
  PutDataWithToken,
} from "../../../ApiHalper/ApiHelper";
import { useSelector } from "react-redux";
import { Profile } from "../profile/Profile";
import { Header } from "../../../commen/Header";
import { Loader } from "../../../commen/Loader";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useDispatch } from "react-redux";
import { actionfaceData } from "../../../store/Action";
import WebcamCapture from "./Camera";
export const FaceRecogniseImage = ({ forAndroidApp }) => {

  const uniqueImages = useSelector((state) => state?.FaceDataReducer);
  const socketImages = [...new Set(uniqueImages)];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [lodar, setLodar] = useState(false);
  const [functions, setFunctions] = useState({selectedFunction: undefined,functions: [],});
  const [eventData, setEventData] = useState({event_data: [],selectedEvent: {},});
  const [historyImage, setHistoryImage] = useState([]);
  const [callApi, setCallApi] = useState(true);
  const [captureImage, setCaptureImage] = useState(null);
  const [previewImageSrc, setPreviewImageSrc] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const faceRecognizeSubmitbuttonRef = useRef(null);
  const apiCallApi = Cookies.get("baseurl");


  useEffect(() => {
    GetDataWithToken("customer/dashboard/events").then((res) => {
      setLodar(true);
      if (res?.success) {
        // setEventData(res?.data?.event);
        setEventData((prevState) => ({
          ...prevState,
          event_data: res?.data?.event,
        }));

        setLodar(false);
      } else if (res.status === 401) {
        navigate("/login");
        Cookies.remove("token");
        toast.error("Session Expire ! Please Login again");
      }
    });

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      let linksFilter = document.querySelector(".links-filter");
      if (scrollTop > 410) {
        linksFilter?.classList?.add("filter-sticky");
      } else {
        linksFilter?.classList?.remove("filter-sticky");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (callApi) {
      GetDataWithToken(`customer/search-history/index`).then((res) => {
        if (res.success) {
          setCallApi(false);
          setHistoryImage(res?.data);
        }
      });
    }
    setImages(socketImages);
  }, [uniqueImages, callApi]);

  const getFunctionsList = (id) => {
    GetDataWithToken(`customer/event/${id}/functions/list`).then((res) => {
      if (res?.success) {
        setFunctions((prevFunctions) => ({
          ...prevFunctions,
          functions: res.data,
        }));
      } else if (res.status === 401) {
        navigate("/login");
        Cookies.remove("token");
        toast.error("Session Expire ! Please Login again");
      }
    });
  };

  const handleFaceRecognize = (e) => {
    dispatch(actionfaceData?.emptyFaceData());
    setImages([]);
    e.preventDefault();
  
    if (!eventData?.selectedEvent?.name) {
      toast.error("Please select Event for Recognization");
      return;
    }
    if (!functions?.selectedFunction) {
      toast.error("Please select Function for Recognization");
      return;
    }
    setLodar(true);
    if (faceRecognizeSubmitbuttonRef.current) {
      faceRecognizeSubmitbuttonRef.current.disabled = true;
    }
    const formDataToSend = new FormData();
    const file = captureImage;
    formDataToSend.append("image", file);
    formDataToSend.append("event_id", eventData?.selectedEvent?.id);
    formDataToSend.append("function_id", functions?.selectedFunction?.id);
    formDataToSend.append("url", `${apiCallApi}`);
    // formDataToSend.append("url", `https://demo.graphterstudio.com`);
    // formDataToSend.append("url", `https://finn.graphterstudio.com/api/customer/event-functions`);

    PostDataWithTokenNodeServerFormData(
      "https://node.graphterstudio.com/upload",
      formDataToSend
    ).then((res) => {
      // PostDataWithTokenNodeServerFormData("http://0.tcp.in.ngrok.io:13552/upload", formDataToSend).then((res) => {
      setLodar(false);
      if (res?.error) {
        toast.error(res?.error);
        if (faceRecognizeSubmitbuttonRef.current) {
          faceRecognizeSubmitbuttonRef.current.disabled = false;
        }
      } else {
        toast.success(res?.message);
        setCallApi(true);
        if (faceRecognizeSubmitbuttonRef.current) {
          faceRecognizeSubmitbuttonRef.current.disabled = false;
        }
      }
      // toast.success("All images successfylly done");
    });
  };

  const handleSelectFunctionId = (id, name) => {
    setFunctions((prevFunctions) => ({
      ...prevFunctions,
      selectedFunction: { id: id, name: name },
    }));
  };

  const handleSelectEvent = (data) => {
    setEventData((prevState) => ({
      ...prevState,
      selectedEvent: data,
    }));
    getFunctionsList(data?.id);
  };

  const handleShowHistoryImage = (data) => {
    setImages([]);
    setImages(data?.event_media);
  };

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleDeleteHistory = (id) => {
    DeleteDataWithToken(`customer/search-history/${id}`).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        setCallApi(true);
      }
    });
  };

  const handleImageReview = (data) => {
    PutDataWithToken(
      `customer/search-history/remove/image/${data?.pivot?.search_history_id}`,
      { event_media_ids: data?.id }
    ).then((res) => {
      if (res?.success) {
        const updatedImages = images.filter((image) => image.id !== data?.id);
        setImages(updatedImages);
        toast.success(res?.message);
      }
    });
  };

  const handleFileChange = (e) => {
    const file = e?.target?.files[0]||captureImage;
    setCaptureImage(e?.target?.files[0]||captureImage);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="tanent-sec">
        {!forAndroidApp && <Header />}
        <div className="after-landing-section">
          {!forAndroidApp && <Profile activeClass={"faceRecognize"} />}
        </div>
        {false ? (
          <Loader />
        ) : (
          <div className="face-recognition">
            <div className="links-filter">
              {!forAndroidApp && (
                <BreadCrumb data={{ links: "Face Recognize" }} />
              )}
              <div className="filters">
                <button
                  className="btn dropdown-toggle filter-btn me-sm-3 me-2"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {eventData?.selectedEvent?.name
                    ? eventData?.selectedEvent?.name
                    : " Select Event"}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {eventData &&
                    eventData?.event_data?.length > 0 &&
                    eventData?.event_data?.map((item, key) => (
                      <li key={key}>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => handleSelectEvent(item)}
                        >
                          {item?.name}
                        </button>
                      </li>
                    ))}
                </ul>{" "}
                <button
                  className="btn dropdown-toggle filter-btn"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {functions?.selectedFunction
                    ? functions?.selectedFunction?.name
                    : "Functions"}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {Object?.entries(functions?.functions)?.map(
                    ([key, value]) => (
                      <li key={key}>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => handleSelectFunctionId(key, value)}
                        >
                          {value}
                        </button>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <div className="after-landing-section">
              <div className="row">
                <div className="col-md-3">
                  {historyImage?.length > 0 && (
                    <div className="history">
                      <h1 className="title24 text-center">History</h1>
                      <div className="card-list">
                        {historyImage &&
                          historyImage?.length > 0 &&
                          historyImage?.map((item, key) => (
                            <div className="card">
                              <button>
                                <img
                                  src={item?.image_path}
                                  className="img-fluid"
                                  alt="Avatar"
                                  onClick={() => handleShowHistoryImage(item)}
                                />
                                <p className="text-center my-2">
                                  <strong>
                                    {item?.event?.name}{" "}
                                    {item?.event_function?.function_name}
                                  </strong>
                                </p>
                              </button>
                              <button
                                className="btns icon title20"
                                onClick={() => handleDeleteHistory(item?.id)}
                                title="Delete"
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    historyImage?.length > 0 ? "col-md-9" : "col-12"
                  }`}
                >
                  <form
                    // onSubmit={(e) => handleFaceRecognize(e)}
                    className="face_image_form"
                  >
                    <div className="upload-area">
                      <div className="upload-header mb-4">
                        <h1 className="title24 mb-2">Upload your image</h1>
                        <p className="text20">
                          File should be an image jpeg, jpg, png etc.
                        </p>
                      </div>
                      <div className="upload-option row justify-content-evenly w-100">
                        <div className="col-xl-5 col-sm-6 -file text-center mb-sm-0 mb-4">
                          <label htmlFor="fileInput" className="dropzone">
                            {!previewImageSrc && (
                              <span className="icon">
                                <i className="fa-solid fa-file-image"></i>
                              </span>
                            )}

                            {/* Image preview */}
                            {previewImageSrc ? (
                              <img
                                src={previewImageSrc}
                                alt="Preview"
                                id="previewImage"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src="assets/media/Photography.jpg"
                                alt="Preview Image"
                                id="previewImage"
                                className="img-fluid d-none"
                              />
                            )}

                            {!previewImageSrc ? (
                              <p className="fw-bold mt-md-3 mt-2 lh-base text16">
                                Drop your file here or <br /> Click to browse
                              </p>
                            ) : (
                              <p className="fw-bold mt-md-3 mt-2 lh-base text16">
                                {captureImage?.name}
                              </p>
                            )}

                            <input
                              className="choose_file d-none"
                              type="file"
                              id="fileInput"
                              name="image"
                              accept="image/*"
                              multiple={false}
                              onChange={handleFileChange}
                            />
                          </label>
                        </div>

                        <div className="col-xl-5 col-sm-6 -camera text-center">
                          <WebcamCapture setCaptureImage={setCaptureImage} handleFileChange={handleFileChange}  />
                        </div>
                      </div>
                    </div>
                    {captureImage && (
                      <button
                        type="button"
                        className="btns btns--calypso submit-btn mt-3"
                        onClick={handleFaceRecognize}
                        ref={faceRecognizeSubmitbuttonRef}
                      >
                        <span>Submit</span>
                      </button>
                    )}
                  </form>
                  <div className={"row mt-3"}>
                    {images?.length > 0 &&
                      images?.map((item, key) => (
                        <div className={"col-xl-3 col-md-4 col-sm-6"}>
                          <div className="event-imgs">
                            <div className="confirmation">
                              <p className="text20">Is That You ? </p>{" "}
                              <button
                                className="title20"
                                onClick={() => handleImageReview(item)}
                              >
                                No
                              </button>
                            </div>
                            <a
                              href="javascript:void(0)"
                              className="porto-item"
                              onClick={() => openLightbox(key)}
                            >
                              <img
                                src={item?.image_original_path}
                                alt="portfolio thumbnail"
                                className="img-fluid object-height"
                                effect="blur"
                                loading="lazy"
                              />
                            </a>
                          </div>
                        </div>
                      ))}
                    {lodar && <Loader fullPageClass={{ class: "w-auto" }} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]?.image_original_path}
            onCloseRequest={() => setIsOpen(false)}
            nextSrc={
              images[(photoIndex + 1) % images?.length]?.image_original_path
            }
            prevSrc={
              images[(photoIndex + images.length - 1) % images?.length]
                ?.image_original_path
            }
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images?.length - 1) % images?.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images?.length)
            }
          />
        )}
      </div>
    </>
  );
};
