/*
 * Api helper File
 * Use for all Api.
 **/
/*
npm run build
*/
import axios from "axios";
import Cookies from "js-cookie";
import cookie from "react-cookies";
import CryptoJS from 'crypto-js';



// export function APPURL() {
//   return APP_URL;
// }
//Post Data Functions.

function getBaseUrl() {
  const MainUrl = Cookies.get("baseurl")
  // console.log(`https://${MainUrl}/api/`)
  return `${MainUrl}/api/`; // Replace with your dynamic base URL source. 
  // return "https://demo.graphterstudio.com/api/";
  // return "https://demo.webbytroops.in/api/";
  // return "https://mumu.graphterstudio.com/api/";
}

// export const DecryptingToken=()=>{
//   const encryptedToken = Cookies.get('token');
//   console.log("api helport",encryptedToken)
//   const bytes = CryptoJS.AES.decrypt(encryptedToken, "webbytroops");
//   return bytes.toString(CryptoJS.enc.Utf8);
// }


export const DecryptingToken = () => {
  const encryptedToken = Cookies.get('token');
  // console.log("Encrypted Token from Cookie:", encryptedToken);

  try {
    // Check if the token exists and is not empty
    if (!encryptedToken) {
      throw new Error("Token not found in cookies.");
    }

     // Perform decryption
     const bytes = CryptoJS.AES.decrypt(encryptedToken, "webbytroops");
     const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
 
     // Logging the decrypted token
    //  console.log("Decrypted Token:", decryptedToken);
 
     return decryptedToken;

  } catch (error) {
    console.error("Decryption error:", error.message);
    return null;
  }
};

export const decryptTokenFlutter = (encryptedToken, passphrase) => {
  console.log("im start",encryptedToken,passphrase)
  try {
    // Decode base64
    const combined = CryptoJS.enc.Base64.parse(encryptedToken);
    if (!combined || !combined.words || combined.words.length === 0) {
      throw new Error("Invalid base64 decoded data");
    }

    const combinedWords = combined.words;
    console.log("Combined words length:", combinedWords.length);

    // Ensure we have enough words for the IV and some encrypted data
    if (combined.sigBytes < 20) { // 16 bytes IV + at least 4 bytes data
      throw new Error("Invalid encrypted token length");
    }

    // Extract IV (first 4 words = 16 bytes) and encrypted data (remaining words)
    const iv = CryptoJS.lib.WordArray.create(combinedWords.slice(0, 4), 16);
    const encryptedData = CryptoJS.lib.WordArray.create(combinedWords.slice(4), combined.sigBytes - 16);

    // Debugging: Log the IV and encryptedData
    console.log("IV:", iv.toString(CryptoJS.enc.Hex));
    console.log("Encrypted data:", encryptedData.toString(CryptoJS.enc.Hex));

    // Perform decryption
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedData },
      CryptoJS.enc.Utf8.parse(passphrase.padEnd(32, ' ')), // Ensure passphrase is 32 bytes
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );

    // Convert decrypted data to UTF-8 string
    const decryptedToken = decrypted.toString(CryptoJS.enc.Utf8);
    if (!decryptedToken) {
      throw new Error("Decryption failed, result is empty");
    }

    return decryptedToken;
  } catch (error) {
    console.error("Error decrypting token:", error);
    return null; // or handle the error as appropriate
  }
};


export function PostData(url, data) {
  const MainUrl = getBaseUrl();

  // body..
  //
  var headers = {
    "Content-Type": "application/json",
    "X-localization": "en",
  };
  return axios
    .post(MainUrl + url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}
//Get Data Functions.
export function GetData(url, data) {
  // body...
  const MainUrl = getBaseUrl();
  // const MainUrl = `${baseUrl}${url}`;

  var headers = {
    "Content-Type": "application/json",
    //'Authorization':'Bearer '+Token,
    //'X-localization':'en'
  };
  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(MainUrl + url, data, { headers: headers })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      if (error.response) {
        let errorStatus = JSON.parse(JSON.stringify(error.response));
        //console.log(errorStatus.data);
        return errorStatus;
      }
    });
}
//Post Data with token
//Post Data Functions.
export function PostDataWithToken(url, data) {
  const MainUrl = getBaseUrl();

  // body..
  //
    const token= DecryptingToken()


  // const token = Cookies.get("token");
  var headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
    // Authorization: token
  };
  return axios
    .post(MainUrl + url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function DeleteDataWithToken(url) {
  const MainUrl = getBaseUrl();

  const token = DecryptingToken()
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios
    .delete(MainUrl + url, { headers: headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      return errorStatus;
    });
}

export function PutDataWithToken(url, data) {
  const MainUrl = getBaseUrl();

  const token = DecryptingToken()
  var headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios
    .put(MainUrl + url, data, { headers: headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      return errorStatus;
    });
}

//Post Data Functions.
export function PostImageDataWithToken(url, data) {
  const MainUrl = getBaseUrl();

  // body..
  //
  let tokens = "";
  if (cookie.load("telimedicineToken")) {
    tokens = cookie.load("telimedicineToken");
  }
  var headers = {
    "Content-Type": "multipart/form-data",
    // Authorization: "Bearer " + tokens,
    Authorization: tokens,
  };
  return axios
    .post(MainUrl + url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}
//Get Data with token Functions.
export function GetDataWithToken(url, data) {
  const MainUrl = getBaseUrl();

  const tokens= DecryptingToken()

  // let tokens = "";

  // if (cookie.load("token")) {
  //   tokens = cookie.load("token");
  // }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      // Authorization: tokens,
    },
    params: {},
  };

  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(MainUrl + url, config)
    .then((res) => {
      //console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log("errors", error);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function GetDataWithTokenForLazy(url, data) {
  // const MainUrl = getBaseUrl();

  let tokens = DecryptingToken()

  // if (cookie.load("token")) {
  //   tokens = cookie.load("token");
  // }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      // Authorization: tokens,
    },
    params: {},
  };

  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(url, config)
    .then((res) => {
      //console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log("errors", error);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function GustGetDataWithTokenForLazy(url, data) {
  // const MainUrl = getBaseUrl();

  let tokens = "";

  if (cookie.load("gustToken")) {
    tokens = cookie.load("gustToken");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      // Authorization: tokens,
    },
    params: {},
  };

  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(url, config)
    .then((res) => {
      //console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log("errors", error);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function PostVideoDataWithToken(url, data, onUploadProgress) {
  const MainUrl = getBaseUrl();

  // body..
  //
  let tokens = "";
  if (cookie.load("telimedicineToken")) {
    tokens = cookie.load("telimedicineToken");
  }
  var headers = {
    "Content-Type": "multipart/form-data",
    // Authorization: "Bearer " + tokens,
    Authorization: tokens,
  };
  return axios
    .post(MainUrl + url, data, {
      headers: headers,
      onUploadProgress: onUploadProgress,
    })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

// FOR GUEST APIS

export function GetDataWithTokenGust(url, data) {
  const MainUrl = getBaseUrl();

  let tokens = "";

  if (cookie.load("gustToken")) {
    tokens = cookie.load("gustToken");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      // Authorization: tokens,
    },
    params: {},
  };

  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(MainUrl + url, config)
    .then((res) => {
      //console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log("errors", error);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function PostDataWithTokenNodeServerFormData(url,data) {
  // body..
  //
  const token = DecryptingToken()
  var headers = {
    // "Content-Type": "application/json", 
    'Content-Type': 'multipart/form-data',
    "access-token": token,
  };
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      // console.log(res);
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      // return error;
      let errorStatus = error?.response?.data;
      return errorStatus;
    });
}


export function PostDataWithTokenNodeServer(url,data) {
  // body..
  //
  const token = DecryptingToken()
  var headers = {
    "Content-Type": "application/json", 
    // 'Content-Type': 'multipart/form-data',
    "access-token": token,
  };
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      // let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      // return errorStatus;
    });
}



