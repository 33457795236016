import AllRoutes from "./component/AllRautes/AllRoutes";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  actionUserInfo,
  actionImageBaseUrl,
  actionLogo,
  actionfaceData,
} from "./component/store/Action";
import { useEffect } from "react";
import { DecryptingToken, GetData, GetDataWithToken } from "./component/ApiHalper/ApiHelper";
import io from "socket.io-client";
import { FaceRecogniseImage } from "./component/pages/tenent/images/FaceRecogniseImage";

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state?.isLoggedInReducer?.action);
  const baseUrl = window.location.origin;
  Cookies.set("baseurl", baseUrl);
  const token = Cookies.get("token");
  const faceRecognizeImage=[]

  useEffect(() => {
    GetData("customer/home-page/theme").then((res) => {
      if (res?.success) {
        dispatch(actionImageBaseUrl.imageBaseUrlSlice(res));

        // Dynamically change the favicon
        const faviconLink =
          document.querySelector("link[rel='icon']") ||
          document.createElement("link");
        faviconLink.rel = "icon";
        faviconLink.type = "image/png";
        faviconLink.href = `${res?.data?.general?.general?.company_fav_icon}`;
        document.head.appendChild(faviconLink);

        const scriptSrc = `${res?.data?.general?.general?.additional_scripts}`;
          const scriptTag = document.createElement("script");
          scriptTag.src = scriptSrc;
          scriptTag.type = "text/javascript";
          document?.head?.appendChild(scriptTag);


        document.documentElement.style.setProperty(
          "--component-bg",
          `${res?.data?.general?.theme?.component_bg.match(/\d+/g).join(",")}`
        );
        document.documentElement.style.setProperty(
          "--background",
          `${res?.data?.general?.theme?.background.match(/\d+/g).join(",")}`
        );
        document.documentElement.style.setProperty(
          "--text",
          `${res?.data?.general?.theme?.text.match(/\d+/g).join(",")}`
        );
        document.documentElement.style.setProperty(
          "--light",
          `${res?.data?.general?.theme?.light.match(/\d+/g).join(",")}`
        );

        return () => {
          document.head.removeChild(faviconLink);
         scriptSrc&& document.head.removeChild(scriptTag);
        };
      }
    });

    if (token) {
      GetDataWithToken("customer").then((res) => {
        if (res?.success) {
          dispatch(actionUserInfo.userInfoSlice(res?.data));
          localStorage.setItem("userData", JSON.stringify(res?.data));
        }
      });
    }
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const logoImg = Cookies.get("logoImg");

    dispatch(actionUserInfo.userInfoSlice(userData));
    dispatch(actionLogo?.logoSlice(logoImg));
  }, [isLoggedIn]);

  const SOCKET_SERVER_URL = "https://node.graphterstudio.com/";  // Replace 
  // const SOCKET_SERVER_URL = "http://0.tcp.in.ngrok.io:14991/";  


  useEffect(() => {
    // Connect to the socket server
    const token=DecryptingToken()
    const socket = io(SOCKET_SERVER_URL);

    // Event listener for connection success
    socket.on("connect", () => {
      console.log("Connected to socket server");
      socket.emit("clientData", { message: "Hello from the client!" }); // Send data to the server
      socket.emit("userId", token); // Send the user's token to the server
    });

    // Event listener for connection errors
    socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    // Event listener for disconnection
    socket.on("disconnect", () => {
      console.log("Disconnected from socket server");
    });

    socket.on("processedImages", ({ paths }) => {
      console.log("Received processed image paths:", paths);
      if(paths?.length>0){
        faceRecognizeImage.push(paths[0])
        dispatch(actionfaceData?.updateFaceData(faceRecognizeImage))
        // console.log("jai shree ram",faceRecognizeImage)
      }
      
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []); // Run this effect only once when the component mounts

  return (
    <div className='main'>
      <AllRoutes />
    </div>
  );
}

export default App;
