import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { GetDataWithTokenGust } from "../../../ApiHalper/ApiHelper";
import { Header } from "../../../commen/Header";
import { GuestImages } from "../guestImages/GuestImages";

export const GuestFolder = ({ handleChildData }) => {
  const location = useLocation();
  const [folderData, setFolderData] = useState([]);
  const [guestData, setGuestData] = useState({});


  useEffect(() => {
    GetDataWithTokenGust("event/dashboard").then((res) => {
      setGuestData(res?.data);
      if (handleChildData) {
        handleChildData(res?.data?.event);
      }
      GetDataWithTokenGust(`event/${res?.data?.event_id}/functions`).then(
        (res) => {
          setFolderData(res?.data);
        }
      );
    });
  }, []);


  return (
    <>
      {/* begin :: header */}
      <Header />
      {/* begin :: guest Profile */}
      <div className="after-landing-section">
        <div className="guest">
          <div className="tanent-profile">
            <div className="profile">
              <p className="name title24">
                <i className="fa-solid fa-user"></i>
                {guestData?.name} |
              </p>
              <p className="email title24">
                <i className="fa-solid fa-envelope" />
                {/* <img src="./assets/media/email.png" alt="Email Icon" width="20px" height="auto"> */}
                <span className="me-1"> {guestData?.email} </span> |
              </p>
              <p className="address title24">
                <i className="fa-solid fa-location-dot" />
                {/* <img src="./assets/media/address.png" alt="Location Icon" width="20px" height="auto"> */}
                <span className="me-1">{guestData?.mob_no}</span>
              </p>
            </div>

            <div className="folders">
              <h1 className="title24">
                Functions
              </h1>
              <ul className="guest_events title20">
                {folderData &&
                  folderData?.length > 0 &&
                  folderData?.map((item, key) => (
                    <li key={key}>
                      <a
                        className={`folder ${guestData?.function_id == item?.id ? 'active' : ''}`}
                        onClick={() =>
                          setGuestData((prevState) => ({
                            ...prevState, // Spread old state
                            function_id: item?.id, // Add new key-value pair
                          }))
                        }
                      >
                        <div className="thumbnail">
                           <img
                            src="../assets/media/imgGroup.png"
                            alt="Image Tag"
                            className="img-fluid"
                          />
                        </div>

                        <h4 className="text24 mb-1">{item?.name}</h4>
                        <p className="text16">{item?.total_image} Images</p>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          {guestData?.function_id && <GuestImages data={guestData?.function_id && { event_id: guestData?.event_id, function_id: guestData?.function_id }} />}
        </div>
      </div>
    </>
  );
};
