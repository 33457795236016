import React from "react";
import { FaceRecogniseImage } from "../tenent/images/FaceRecogniseImage";

export const Androidapp = () => {
  return (
    <>
      <FaceRecogniseImage forAndroidApp={true} />
    </>
  );
};
