import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { PostDataWithToken } from "../ApiHalper/ApiHelper";
import { actionUserLoggedIn } from "../store/Action";
import { toast } from "react-toastify";


export const Header = () => {
  const [isBodyClassActive, setIsBodyClassActive] = useState(true)
  const token = Cookies.get("token");
  const userInfo = useSelector((state) => state?.userInfo?.action?.customer);
  // const logoImg = useSelector((state) => state?.logoReducer?.action);
  const logoImg = Cookies.get("logoImg")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (event.target.classList.contains('bg-overlay')) {
        document.body.classList.remove('overlay-open')
        event.stopPropagation(); // Stop the event from propagating
        setIsBodyClassActive(true)
      }
    };
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      let headerMenu = document.querySelector('.header-menu');
      if (scrollTop >= 400) {
        headerMenu?.classList?.add("header-sticky");     
      } else {
        headerMenu?.classList?.remove("header-sticky");
      }
    };  
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); 


  const handleLogout = () => {
    handleBodyClassToggle();
    PostDataWithToken("customer/logout").then((res) => {
      if (res?.success) {
        Cookies.remove("token");
        toast.success("You have been logged out successfully.");
        Cookies.remove("token");
        localStorage.clear("userData");
        dispatch(actionUserLoggedIn.isLoggedInSlice(false));
        navigate("/");
      }
    });
  };

  const handleBodyClassToggle = () => {
    setIsBodyClassActive(!isBodyClassActive)
    if (isBodyClassActive) {
      document.body.classList.add('overlay-open')
    }
    else {
      document.body.classList.remove('overlay-open')
    }
  }

  return (
    <>
      {/* begin :: background overlay */}
      <div className="bg-overlay"></div>
      {/* end :: background overlay */}

      {/* begin :: navabar */}
      {/* <header className={`header-menu ${location?.pathname == '/' ? 'header-items-fix' : ''}`}> */}
      <header className={`header-menu ${location?.pathname === '/' || location?.pathname === '/guest/Folder' || location?.pathname === '/service/List' || location?.pathname === '/service/Detail' ? 'header-items-fix' : ''}`}>

        {/* begin::brandd logo */}
        <Link className="brandlogo" to="/">
          <img
            src={logoImg}
            alt="brand-logo"
            className="img-fluid" />
        </Link>
        {/* end :: brand logo */}

        {/* <nav className="navbar navbar-dark navmenu"> */}
        <nav className={`navbar navmenu ${isBodyClassActive ? '' : 'open'}`}>
          <div className="container-fluid p-0">

            {/* begin::mobile hamburger */}
            <div className="menu-btn text24" title="Menu" data-bs-toggle="collapse" onClick={handleBodyClassToggle} data-bs-target="#navbarSupportedContent">
              {/* <span></span>
            <span></span> */}
              <strong>Menu</strong>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" d="M20,22 C18.8954305,22 18,21.1045695 18,20 C18,18.8954305 18.8954305,18 20,18 C21.1045695,18 22,18.8954305 22,20 C22,21.1045695 21.1045695,22 20,22 Z M20,14 C18.8954305,14 18,13.1045695 18,12 C18,10.8954305 18.8954305,10 20,10 C21.1045695,10 22,10.8954305 22,12 C22,13.1045695 21.1045695,14 20,14 Z M20,6 C18.8954305,6 18,5.1045695 18,4 C18,2.8954305 18.8954305,2 20,2 C21.1045695,2 22,2.8954305 22,4 C22,5.1045695 21.1045695,6 20,6 Z M12,22 C10.8954305,22 10,21.1045695 10,20 C10,18.8954305 10.8954305,18 12,18 C13.1045695,18 14,18.8954305 14,20 C14,21.1045695 13.1045695,22 12,22 Z M12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 Z M12,6 C10.8954305,6 10,5.1045695 10,4 C10,2.8954305 10.8954305,2 12,2 C13.1045695,2 14,2.8954305 14,4 C14,5.1045695 13.1045695,6 12,6 Z M4,22 C2.8954305,22 2,21.1045695 2,20 C2,18.8954305 2.8954305,18 4,18 C5.1045695,18 6,18.8954305 6,20 C6,21.1045695 5.1045695,22 4,22 Z M4,14 C2.8954305,14 2,13.1045695 2,12 C2,10.8954305 2.8954305,10 4,10 C5.1045695,10 6,10.8954305 6,12 C6,13.1045695 5.1045695,14 4,14 Z M4,6 C2.8954305,6 2,5.1045695 2,4 C2,2.8954305 2.8954305,2 4,2 C5.1045695,2 6,2.8954305 6,4 C6,5.1045695 5.1045695,6 4,6 Z"></path> </g></svg>
            </div>
            {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button> */}
            {/* end::mobile hamburger */}
            <div className={`navbar-collapse  ${isBodyClassActive ? 'collapse' : ''}`} id="navbarSupportedContent">
              {/* begin::navbar menu */}
              <ul className="navbar-nav ms-auto">
                <li className={`nav-item`} onClick={handleBodyClassToggle}>
                  <Link to={"/#Home"} className="nav-link">
                  <span>Home</span>
                  </Link>
                </li>
                <li
                  className={`nav-item`} onClick={handleBodyClassToggle}
                >
                  <Link to="/#about" className="nav-link">
                    <span>About US</span>
                  </Link>
                </li>
                <li className={`nav-item`} onClick={handleBodyClassToggle}>
                  <Link className="nav-link" to="/#srvc">
                    <span>Services</span>
                  </Link>
                </li>
                <li className={`nav-item`} onClick={handleBodyClassToggle}>
                  <Link className="nav-link" to="/#feature">
                    <span>Features</span>
                  </Link>
                </li>
                <li className={`nav-item`} onClick={handleBodyClassToggle}>
                  <Link className="nav-link" to={"/#portfolio"}>
                    <span>Portfolio</span>
                  </Link>
                </li>
                <li className={`nav-item`} onClick={handleBodyClassToggle}>
                  <Link className="nav-link" to={"/#contect"}>
                    <span>Contact US</span>
                  </Link>
                </li>
              </ul>
              {/* end::navbar menu */}
              {/* begin::navbar profile */}
              <div className="profileloginbtn">
                <Link
                  to={"/login"}
                  className={`${token && "d-none"} btns btns--calypso login-btn`}
                  onClick={() => document.body.classList.remove('overlay-open')}
                >
                  <span>
                    login
                    <svg viewBox="0 0 863.75 896.29">
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                          <g id="logout">
                            <path
                              className="cls-1"
                              d="M479,0H559.1s80.36-.27,80.36,80.36v90.1H554.35l0-85.85H155.23l228.48,85.85v512H554.32V596.71H640v90.43s0,80.32-80.32,80.32h-176V896.29L68,766.5S-1.68,750,0,689.93V81.64S0,0,81,.68C161.17.5,479,0,479,0Z" />
                            <polygon
                              className="cls-1"
                              points="602.6 426.79 863.75 426.79 863.75 341.64 602.6 341.64 670.03 274.21 608.6 212.79 437.75 383.64 609.46 555.36 669.46 495.36 602.6 426.79" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>

                </Link>

                {<button
                  className={`${!token && "d-none"} nav-link dropdown-toggle profile-icon`}
                  data-bs-toggle="dropdown"
                >
                  {/* {userInfo?.firstname?.[0]} {userInfo?.lastname?.[0]} */}
                  <span className="profilepic">{userInfo?.firstname?.[0]} {userInfo?.lastname?.[0]}</span>
                  <span className="profilename">{userInfo?.firstname} {userInfo?.lastname}</span>
                </button>}
                <ul className="dropdown-menu">
                  <li onClick={handleBodyClassToggle}>
                    <Link to={'/profile/Event'} className="dropdown-item"><span>profile</span></Link>
                  </li>
                  <li onClick={handleLogout}>
                    <button className="dropdown-item"><span>Logout</span></button>
                  </li>
                  {/*                <hr className="dropdown-divider"/>
                <li onClick={handleBodyClassToggle}>
                  <a className="dropdown-item">Other</a>
                </li> */}
                </ul>
              </div>
              {/* end::navbar profile */}
            </div>
          </div>
        </nav>
        {/* end::navabar */}
      </header>
    </>
  );
};
